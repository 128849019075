import { graphql, PageProps } from 'gatsby';
import debounce from 'lodash/debounce';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useMemo
} from 'react';
import { Footer } from '../components/footer/footer';
import { Navbar } from '../components/navbar/navbar';
import RichText from '../components/rich-text/rich-text';
import { SeoComponent } from '../components/seo-component/seo-component';
import { DEAFUALT_LANGUAGE } from '../const/languages';
import { withCookieBar } from '../hoc/withCookieBar';
import { CategoryModel, ICategoryModel } from '../models/category-model';
import { AppActionType, AppStore, withAppStore } from '../store/app.context';
import '../styles/global.css';
import '../styles/reset.css';
import { pageDataResolver } from '../utils/pageDataResolver';
import { filterById } from '../utils/utils';

interface IData {
  allKontentItemCaseStudyArticle: {
    nodes: ICaseStudyListItem[];
  };
  kontentItemPage: IPage;
  allKontentItemCaseStudyIndustry: {
    nodes: ICategory[];
  };
  allKontentItemCaseStudyProject: {
    nodes: ICategory[];
  };
  allKontentItemCaseStudyTechnology: {
    nodes: ICategory[];
  };
  allKontentItemCaseStudyCountry: {
    nodes: ICategory[];
  };
  cookieSection: ICookieData;
}

export interface ICaseStudyCategories {
  industryCategories: ICategoryModel[] | null;
  projectCategories: ICategoryModel[] | null;
  technologyCategories: ICategoryModel[] | null;
  countryCategories: ICategoryModel[] | null;
}

type CaseStudiesPageProps = PageProps<IData, IPageContext>;

const CaseStudiesPage: FunctionComponent<CaseStudiesPageProps> = props => {
  const { dispatch, state } = useContext(AppStore);

  useEffect(() => {
    const handleResize = (): void =>
      dispatch({
        type: AppActionType.deviceSize,
        payload: window.innerWidth
      });
    const handleChangePageState = (): void =>
      dispatch({
        type: AppActionType.pageState,
        payload: {
          prevUrl: props.location.state ? props.location.state.prevUrl : '',
          currentUrl: props.pageContext.pageUrl || '/',
          currentUri: props.uri,
          currentLanguage: props.pageContext.pageLanguage || DEAFUALT_LANGUAGE
        }
      });
    const handleCookieData = (): void => {
      dispatch({
        type: AppActionType.cookieData,
        payload: {
          agreementText: props.data.cookieSection.elements.text.value as string,
          buttonCta: props.data.cookieSection.elements.button_cta
            .value as string
        }
      });
    };
    handleCookieData();

    const debouncedResize = debounce(handleResize, 300);

    handleResize();
    handleChangePageState();
    window.addEventListener('resize', debouncedResize);
    return (): void => {
      window.removeEventListener('resize', debouncedResize);
    };
  }, [
    dispatch,
    state.showActiveDevice,
    state.showPageState.currentUri,
    props.pageContext.pageLanguage,
    props.pageContext.pageUrl,
    props.uri,
    props.location.state,
    props.data.cookieSection.elements.text.value,
    props.data.cookieSection.elements.button_cta.value
  ]);

  const caseStudyCategoriesData: ICaseStudyCategories = useMemo(() => {
    const filteredIndustry = (filterById(
      props.data.allKontentItemCaseStudyIndustry.nodes
    ) as unknown) as ICategory[];

    const filteredProject = (filterById(
      props.data.allKontentItemCaseStudyProject.nodes
    ) as unknown) as ICategory[];

    const filteredTechnology = (filterById(
      props.data.allKontentItemCaseStudyTechnology.nodes
    ) as unknown) as ICategory[];

    const filteredCountry = (filterById(
      props.data.allKontentItemCaseStudyCountry.nodes
    ) as unknown) as ICategory[];

    const industryCategories = filteredIndustry
      ? filteredIndustry.map((category: ICategory) =>
          CategoryModel.create(category)
        )
      : null;

    const projectCategories = filteredProject
      ? filteredProject.map((category: ICategory) =>
          CategoryModel.create(category)
        )
      : null;

    const technologyCategories = filteredTechnology
      ? filteredTechnology.map((category: ICategory) =>
          CategoryModel.create(category)
        )
      : null;

    const countryCategories = filteredCountry
      ? filteredCountry.map((category: ICategory) =>
          CategoryModel.create(category)
        )
      : null;

    return {
      industryCategories: industryCategories,
      projectCategories: projectCategories,
      technologyCategories: technologyCategories,
      countryCategories: countryCategories
    };
  }, [
    props.data.allKontentItemCaseStudyIndustry,
    props.data.allKontentItemCaseStudyProject,
    props.data.allKontentItemCaseStudyTechnology,
    props.data.allKontentItemCaseStudyCountry
  ]);

  const caseStudiesData = useMemo(
    () => props.data.allKontentItemCaseStudyArticle || {},
    [props.data.allKontentItemCaseStudyArticle]
  );

  const pageData = useMemo(() => pageDataResolver(props.data.kontentItemPage), [
    props.data.kontentItemPage
  ]);

  return (
    <>
      <SeoComponent {...pageData.seoData} />
      {(pageData.navbarData.links || pageData.navbarData.button.text) && (
        <Navbar {...pageData.navbarData} />
      )}
      <RichText
        {...props.data.kontentItemPage.elements.body}
        caseStudies={caseStudiesData}
        caseStudyCategories={caseStudyCategoriesData}
      />
      {pageData.footerData.linkColumns.length && (
        <Footer {...pageData.footerData} />
      )}
    </>
  );
};

export default withAppStore(
  withCookieBar(CaseStudiesPage as FunctionComponent<{}>)
);

export const query = graphql`
  query($pageId: String, $pageLanguage: String) {
    allKontentItemCaseStudyArticle(
      filter: { system: { language: { eq: $pageLanguage } } }
      sort: { fields: elements___publication_date___value, order: DESC }
    ) {
      nodes {
        ...FragmentCaseStudyListItem
      }
    }
    allKontentItemCaseStudyIndustry {
      nodes {
        ...FragmentCaseStudyCategoryIndustry
      }
    }
    allKontentItemCaseStudyProject {
      nodes {
        ...FragmentCaseStudyCategoryProject
      }
    }
    allKontentItemCaseStudyTechnology {
      nodes {
        ...FragmentCaseStudyCategoryTechnology
      }
    }
    allKontentItemCaseStudyCountry {
      nodes {
        ...FragmentCaseStudyCategoryCountry
      }
    }
    kontentItemPage(system: { id: { eq: $pageId } }) {
      ...FragmentPageComponent
      ...FragmentPageNavbar
      ...FragmentPageFooter
    }
    cookieSection: kontentItemCookietext(
      system: { language: { eq: $pageLanguage } }
    ) {
      ...FragmentCookieBarItems
    }
  }
`;
